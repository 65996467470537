<template>
  <div class="flex flex-col">
    <div class="flex-1">
      <div class="text-white bg-dark">
        <h1 class="pt-10 ml-4 font-extrabold text-mhead lg:hidden">Our evaluation</h1>

        <div class="container flex flex-col-reverse pl-5 pr-4 mx-auto mb-10 lg:pr-20 lg:flex-row">
          <div class="py-8 lg:w-1/2">
            <h1 class="hidden text-mhead lg:text-head lg:block">Our evaluation</h1>
            <div v-if="result">
              <p class="block font-medium" v-html="result.Evaluation"></p>
              <h2 class="mt-4 text-lg font-bold" v-if="result.Splint">Splint type:</h2>
              <p class="font-bold text-primary" v-html="result.Splint"></p>
              <h2 class="mt-4 text-lg font-bold" v-if="result.Tip">Technique:</h2>
              <p class v-html="result.Tip"></p>
            </div>
          </div>
          <div class="lg:w-1/2 lg:mt-10">
            <img src="../assets/QuotesAnimation2.gif" />
          </div>
        </div>
      </div>
      <div class="container mx-auto">
        <h2 class='px-4 mb-6 text-3xl font-bold text-center'
        :class="choices.length<3 ? 'md:text-left':''"
        v-if="choices.length"
        >We found these braces just for you:</h2>
        <!-- Desktop -->
        <div class="hidden grid-cols-3 gap-3 mx-4 md:grid">
          <div class="flex items-center p-2 bg-shade" v-for="choice in choices" :key="choice.id">
            <div style="max-width:500px" class="mx-auto">
              <img :src="choice.Photo_1" :alt="choice.Name" @click="details(choice.IDX)" class="cursor-pointer"/>
            </div>
          </div>
          <div v-if="choices.length<2"></div>
          <div v-if="choices.length<3"></div>

          <div
            class="block text-xl text-primary"
            v-html="choice.label"
            v-for="choice in choices"
            :key="choice.id"
          ></div>
          <div v-if="choices.length<2"></div>
          <div v-if="choices.length<3"></div>

          <div
            class="block font-bold"
            v-html="choice.Name"
            v-for="choice in choices"
            :key="choice.id"
          ></div>
          <div v-if="choices.length<2"></div>
          <div v-if="choices.length<3"></div>

          <div class="block" v-html="choice.Price" v-for="choice in choices" :key="choice.id"></div>
          <div v-if="choices.length<2"></div>
          <div v-if="choices.length<3"></div>

          <stars :rating="choice.Rating" v-for="choice in choices" :key="choice.id" />
          <div v-if="choices.length<2"></div>
          <div v-if="choices.length<3"></div>

          <div v-for="choice in choices" :key="choice.id">
              <red-button type="button" @click="details(choice.IDX)">View Details</red-button>
          </div>
          <div v-if="choices.length<2"></div>
          <div v-if="choices.length<3"></div>
        </div>
        <!-- Mobile -->
        <div class="px-4 mb-6 md:hidden" v-for="(choice,index) in choices" :key="choice.id">
            <hr class='mt-4 mb-6' v-if="index >0">
          <div class="flex items-center p-2 bg-shade">
            <div style="max-width:500px" class="mx-auto">
              <img :src="choice.Photo_1" :alt="choice.Name" @click="details(choice.IDX)" class="cursor-pointer"/>
            </div>
          </div>

          <div class="block my-4 text-xl text-primary" v-html="choice.label"></div>

          <div class="block font-bold" v-html="choice.Name"></div>

          <div class="block" v-html="choice.Price"></div>

          <stars :rating="choice.Rating" class="my-4"/>

          <div>
              <red-button type="button" @click="details(choice.IDX)">View Details</red-button>
          </div>
        </div>
      </div>
    </div>

    <footer-md class="px-10 mt-10 text-gray-700 lg:px-0 lg:mt-6" />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Stars from "../components/Stars.vue";
import {analytics }from "../firebase";
export default {
  name: "Evaluation",
  //   props: ["query"],
  components: {
    "footer-md": Footer,
    stars: Stars,
  },
  methods: {
    details(idx) {
      window.router.push({
        name: "Detail",
        params: { idx: idx },
      });
    },
    getChoices() {
      //   console.log(this.result);
      this.choices[0] = this.$parent.cms.products[
        this.$parent.slug(this.result.Choice)
      ];
      this.choices[1] = this.$parent.cms.products[
        this.$parent.slug(this.result.Alt_2)
      ];
      this.choices[2] = this.$parent.cms.products[
        this.$parent.slug(this.result.Alt_1)
      ];
      if (this.choices[0]) this.choices[0].label = "Our recommendation";
      else this.choices.splice(0);
      if (this.choices[1]) this.choices[1].label = "Another good option";
      else this.choices.splice(1);
      if (this.choices[2]) this.choices[2].label = "Another good option";
      else this.choices.splice(2);
      if (this.choices.length == 0) {
        this.$parent.alert("No recommendations yet...");
        // window.history.back();
      }
    },
    evaluate() {
      var slug;
      this.result = null;
      this.choices = [];


      switch (this.$route.params.from) {
        case "splint":
          this.$parent.cms.splints.forEach((doc) => {
            slug = this.$parent.slug(doc.Splint);
            if (this.$route.params.detail == slug) {
              this.result = doc;
              return;
            }
          });
          break;
        case "condition":
          this.$parent.cms.conditions.forEach((doc) => {
            slug = this.$parent.slug(doc.Condition);
            if (this.$route.params.detail == slug) {
              this.result = doc;
              return;
            }
          });
          break;
        case "painpoint":
          this.$parent.cms.painpoints.forEach((doc) => {
            if (doc.Points.indexOf(parseInt(this.$route.params.detail)) > -1) {
              this.result = doc;
              return;
            }
          });
          break;
        case "search":
          this.$parent.cms.conditions.forEach((doc) => {
            if (
              doc.Description.toLowerCase().search(
                this.$route.params.detail.toLowerCase()
              ) >= 0 &&
              doc.Choice.trim() != ""
            ) {
              this.result = doc;
              return;
            }
            if (
              doc.Evaluation.toLowerCase().search(
                this.$route.params.detail.toLowerCase()
              ) >= 0 &&
              doc.Choice.trim() != ""
            ) {
              this.result = doc;
              return;
            }
          });
          if (!this.result) {
            this.$parent.cms.splints.forEach((doc) => {
              if (
                doc.Description.toLowerCase().search(
                  this.$route.params.detail.toLowerCase()
                ) >= 0 &&
                doc.Choice.trim() != ""
              ) {
                this.result = doc;
                return;
              }
              if (
                doc.Evaluation.toLowerCase().search(
                  this.$route.params.detail.toLowerCase()
                ) >= 0 &&
                doc.Choice.trim() != ""
              ) {
                this.result = doc;
                return;
              }
            });
          }
          if (!this.result) {
            this.$parent.alert(
              'We could not find anything related to "' +
                this.$route.params.detail +
                '"'
            );
            window.history.back();
          }
          break;
      }
      this.getChoices();
      this.$nextTick(() => {
          analytics.logEvent("Evaluation:"+this.$route.params.from+'-'+this.$route.params.detail);
      })
    },
  },
  watch: {
    "$route.path"() {
      this.evaluate();
    },
  },
  mounted() {
    this.evaluate();
  },
  data() {
    return {
      result: null,
      choices: [],
    };
  },
};
</script>

<style>
</style>k
